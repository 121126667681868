export function toFloat(value: any, defaultValue?: number) {
    const float = parseFloat(value)
    return isNaN(float) ? defaultValue || 0 : float
}

export function toPixels(value: any) {
    return value + 'px';
}

export function toPercents(value: any) {
    return value + '%';
}

export const isElement = (el: any) => !!(el && el.nodeType === Node.ELEMENT_NODE)

export const setStyle = (el: any, prop: any, value: any) => {
    if (prop && isElement(el)) {
        el.style[prop] = value
    }
}

export const getStyle = (el: any, prop: any) => (prop && isElement(el) ? el.style[prop] || null : null)

export const mathCeil = Math.ceil;
export const mathMax = Math.max;
export const mathMin = Math.min;
